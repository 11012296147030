import { ServicePageMigrator } from './service-page-migrator';
import { CalendarPageMigrator } from './calendar-page-migrator';
import { FormPageMigrator } from './form-page-migrator';
import { EcomPagesMigrator } from './ecom-pages-migrator';
import { BasePageMigrator } from './base-page-migrator';
import { ServiceListMigrator } from './service-list-migrator';
import { isBookingsCheckoutInstalled, isEcomCheckoutInstalled } from '../editor-sdk-actions';

export const migrateToOOI = async ({
  editorSDK,
  appToken,
  instance,
  editorScriptApi,
}) => {
  const bookingsCheckoutInstalled = await isBookingsCheckoutInstalled(editorSDK);
  const ecomCheckoutInstalled = await isEcomCheckoutInstalled(editorSDK);

  if (!ecomCheckoutInstalled && !bookingsCheckoutInstalled) {
    throw new Error('neither Booking Checkout or eCom Checkout Page exist. errorCode: 6cf57023-c617-4a0a-a030-64379ca9615f');
  }

  const pageMigrators: BasePageMigrator[] = [
    new ServiceListMigrator(editorSDK, appToken, instance),
    new ServicePageMigrator(editorSDK, appToken, instance, editorScriptApi),
    new CalendarPageMigrator(editorSDK, appToken, instance),
    new FormPageMigrator(editorSDK, appToken, instance),
    new EcomPagesMigrator(editorSDK, appToken, instance, editorScriptApi),
  ];

  for (const pageMigrator of pageMigrators) {
    try {
      if (await pageMigrator.shouldMigrate()) {
        await pageMigrator.execute();
      }
    } catch (e) {
      const errorMessage = `${(e?.message ? e.message : JSON.stringify(e))} - errorCode: OOI_MIGRATION, page: ${pageMigrator.widgetName}`;
      throw new Error(errorMessage);
    }
  }
  console.log('migration completed successfully');
};
